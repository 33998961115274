import React from "react"
import styled from "@emotion/styled"
import Project from "../projects/project"
import Flex from "../projects/templateflex"
import Image from "../components/image"
import { Helmet } from "react-helmet"

const Title = styled.h2`
  color: #362b3b;

  text-transform: uppercase;
  font-size: 26px;
  margin-bottom: 20px;
  flex: 0 0 95%;
  margin-left: 2.5%;
`
const SubTitle = styled.h3`
  color: #362b3b;

  font-size: 20px;
`
const Desc = styled.p`
  flex: 0 0 95%;
  margin-left: 2.5%;
  margin-bottom: 30px;
`
const Text = styled.p`
  height: auto;
`
const TextBox = styled.div`
  flex: 0 0 42.5%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(6) {
    order: 6;
  }
  &:nth-child(7) {
    order: 7;
  }
  @media (max-width: 760px) {
    flex: 0 0 95%;
    margin-left: 2.5%;
    &:nth-child(6) {
      order: 5;
    }
  }
`

const Img = styled.div`
  border: 0.01px #dcdcdc solid;
  box-shadow: 0px 18px 25px rgba(0, 0, 0, 0.15);
  flex: 0 0 42.5%;
  margin-bottom: 30px;
  &:nth-child(4) {
    order: 4;
  }
  &:nth-child(5) {
    order: 5;
  }
  &:nth-child(8) {
    order: 8;
  }

  @media (max-width: 760px) {
    flex: 0 0 95%;
    margin-left: 2.5%;
    &:nth-child(5) {
      order: 6;
    }
  }
`
const Vtts = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Portfolio- VTTS Finance</title>
      <link rel="canonical" href="https://avtar.dev/vtts" />
    </Helmet>
    <Project>
      <Flex>
        <Title>VTTS Financing</Title>
        <Desc>
          This project was for the launch of a new equipment financing business.
          The client needed a stronger online presence which was especially
          important during COVID. I was also hired to design the website and
          make sure it fufilled their SEO requirements.
        </Desc>
        <TextBox>
          <SubTitle>Look & Feel</SubTitle>
          <Text>
            During this project, I felt like I started to finally get the hang
            of Figma as a web design tool. I designed the website from scratch
            as the client only had a few visuals and the logo. They wanted the
            website to look sleek and professional so I kept this in mind when
            drawing it up.
          </Text>
        </TextBox>
        <Img>
          {" "}
          <Image fileName="vtts-1.png" style={{ width: "100%" }} alt="trucks" />
        </Img>
        <Img>
          {" "}
          <Image fileName="vtts-2.png" style={{ width: "100%" }} alt="trucks" />
        </Img>
        <TextBox>
          <SubTitle>Client Relations</SubTitle>
          <Text>
            The more client projects that I work on, the better I get at scoping
            projects and understanding the client’s expectations. I’ve developed
            a better sense of what I need to do or learn in order to complete a
            project. I’ve also realized that even if I’m unfamiliar with a
            technology at the beginning of a project, I can easily pick it up
            through practice and online resources.
          </Text>
        </TextBox>
        <TextBox>
          <SubTitle>Future Improvements</SubTitle>
          <Text>
            As the client’s business develops, they expressed the future need
            for a more robust form submission to collect and store client info.
            Considering it handles confidential financial information, data
            security is a priority for them.
          </Text>
        </TextBox>
        <Img>
          <Image fileName="vtts-3.png" style={{ width: "100%" }} alt="trucks" />
        </Img>
      </Flex>
    </Project>
  </>
)

export default Vtts
